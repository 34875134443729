
.grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  padding: 0px;
  gap: 20px;
  margin-bottom: 20px;
}
.grid-1-col {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-2-col {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-3-col {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-4-col {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-5-col {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid-6-col {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid-7-col {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
.grid-8-col {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}
.ml6 {
  margin-left: 6px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mt10 {
  margin-top: 10px;
}
.right {
  text-align: right;
}

.page_head:after {
  content: "";
  clear: both;
  display: table;
}

.page_head .page_actions button {
  float: right;
  margin: 5px 0 0 10px;
}

.page_head .pageHead {
  float: left;
}

.page_head .page_actions {
  float: right;
  margin-bottom: 20px;
}
.pageHead {
  margin-bottom: 20px;
}

.page_head .bl_search {
  float: left;
  margin-left: 30px;
  margin-right: 30px;
}
.page_head .bl_search input,
.page_head .bl_search fieldset {
  border-radius: 20px;
  line-height: 0.8em;
}
.page_head .bl_search button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  margin: 0;
}

.menuItem {
  display: block !important;
  width: 100%;
  text-align: left;
  padding: 10px !important;
}
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}
.tbl_plain {
  width: 100%;
}
.username {
  text-transform: capitalize;
}

.bl_contact_person::after {
  clear: both;
  content: "";
  display: table;
}

.bl_contact_person .bl_form {
  width: calc(50% - 20px);
  float: left;
  margin-bottom: 20px;
}

.bl_contact_person .bl_content {
  width: 50%;
  float: right;
  margin-bottom: 20px;
}

.error {
  color: #d32f2f;
  margin-bottom: 20px;
}

.checkboxGroup {
  display: block !important;
  font-size: 0.8em !important;
}
.checkboxGroup .groupCheckbox {
  display: inline-block !important;
}
.bl_date {
  display: flex;
}
.bl_date .clearHandler {
  margin-left: 5px;
}
.tableTextfield {
  align-self: center;
}

@media (max-width: 1366px) {
  .grid-6-col {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .grid-4-col {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 1025px) {
  .grid-6-col {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid-4-col {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 801px) {
  .grid-6-col {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid-4-col {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .page_head .pageHead {
    float: none;
  }
  .page_head .page_actions {
    text-align: left;
    float: none;
    margin-bottom: 20px;
  }
  .page_head .page_actions button {
    float: none;
    margin: 0 10px 0 0 !important;
  }
}
@media (max-width: 600px) {
  .grid-3-col,
  .grid-1-col {
    grid-template-columns: 1fr;
  }
  .page_actions {
    text-align: left;
    float: none;
  }
}
@media (max-width: 480px) {
  .grid-3-col,
  .grid-1-col {
    grid-template-columns: 1fr;
  }
}

/* input:focus {
  background-color: #fafafa;
}
.Mui-focused {
  background-color: #fafafa;
} */

.red_highlight {
  color: red;
  font-weight: 600;
}
.fr {
  float: right;
}

.tbl_lr {
  width: 100%;
  border-collapse: collapse;
}

.tbl_lr td {
  border: 1px solid #ccc;
  padding: 10px;
}

.tbl_lr tr td:nth-child(2n) {
  font-weight: bold;
}

.ack_display_image {
  box-sizing: border-box;
  width: 535px;
  height: 301px;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  position: relative;
  margin: auto;
}
.ack_display_image .btn_remove {
  position: absolute;
  left: 0;
  top: 0;
}
