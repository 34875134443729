.bl_login {
  display: flex;
  /* max-width: 450px; */
  margin: auto;
  /* max-width: 650px; */
}

.bl_login .MuiPaper-root {
  padding: 0 !important;
}

.login-wrap {
  display: flex;
  min-height: 390px;
}

.login-card {
  width: 50%;
  padding: 20px;
}

.login-logo-pnl {
  width: 50%;
  display: flex;
  align-items: center;
  background: #dce1ec;
  padding-left: 0px;
  border-radius: 4px 0px 0px 4px;
  text-align: center;
}

.btn-login {
  width: 100%;
}

.bg-login {
  /* background: rgba(0, 0, 0, 0.4) url(../assets/login-design_ver_01_bkup.jpg)
    no-repeat;
  background-size: cover;
  background-position: 0px -80px; */
  height: 100vh;
  margin-top: -90px;
  /* padding-top: 50px; */
}

.login-logo-pnl img {
  width: 250px;
  margin: 0 auto;
  height: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  padding: 0px;
  gap: 20px;
  margin-bottom: 20px;
}
.grid-1-col {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-2-col {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-3-col {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-4-col {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-5-col {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid-6-col {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid-7-col {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
.grid-8-col {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}
.ml6 {
  margin-left: 6px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mt10 {
  margin-top: 10px;
}
.right {
  text-align: right;
}

.page_head:after {
  content: "";
  clear: both;
  display: table;
}

.page_head .page_actions button {
  float: right;
  margin: 0px 0 0 0px;
  height: 39px;
  font-weight: normal;
  background-color: #0b0e12;
}

.page_head .page_actions .multi-select button {
  float: none !important;
  height: auto !important;
  background-color: transparent !important;
}

.text-inline {
  white-space: nowrap;
}

.page_head .pageHead {
  float: left;
}

.MuiDataGrid-root .MuiIconButton-colorError svg {
  fill: #cd2d3f !important;
}

.MuiDataGrid-withBorderColor .MuiSvgIcon-root {
  width: 1.2rem;
  height: 1.2rem;
}

.headPipe {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.page_head .page_actions {
  float: right;
  margin-bottom: 20px;
}
.pageHead {
  margin-bottom: 20px;
  font-size: 22px;
}

.pageHead .welcome-text {
  font-weight: 100;
  font-size: 28px;
}

.homeTitle {
  font-size: 34px;
}

.page_head .bl_search {
  float: left;
  margin-left: 15px;
  margin-right: 15px;
}
.page_head .bl_search input,
.page_head .bl_search fieldset {
  border-radius: 4px 0px 0 4px;
  line-height: 0.8em;
}

.page_head .bl_search input {
  height: 16px;
}

.page_head .bl_search button {
  width: 35px;
  height: 33px;
  border-radius: 0px 4px 4px 0px;
  display: inline-block;
  margin: 0 0 0 -1px !important;
  background-color: #0b0e12 !important;
}

.menuItem {
  display: block !important;
  width: 100%;
  text-align: left;
  padding: 10px !important;
}
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}
.tbl_plain {
  width: 100%;
}
.username {
  text-transform: capitalize;
}

.bl_contact_person::after {
  clear: both;
  content: "";
  display: table;
}

.bl_contact_person .bl_form {
  width: calc(50% - 20px);
  float: left;
  margin-bottom: 20px;
}

.bl_contact_person .bl_content {
  width: 50%;
  float: right;
  margin-bottom: 20px;
}

.error {
  color: #d32f2f;
  margin-bottom: 20px;
}

.checkboxGroup {
  display: block !important;
  font-size: 0.8em !important;
}
.checkboxGroup .groupCheckbox {
  display: inline-block !important;
}
.bl_date {
  display: flex;
}
.bl_date .clearHandler {
  margin-left: 5px;
}
.tableTextfield {
  align-self: center;
}

@media (max-width: 1366px) {
  .grid-6-col {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .grid-4-col {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 1025px) {
  .grid-6-col {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid-4-col {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 850px) {
  .main-wrap {
    /* width: 850px;
    overflow: auto; */
  }

  .inner-wrap {
    width: 950px;
    overflow: auto;
  }

  .userpButtons {
    right: 0px !important;
  }
}

@media (max-width: 801px) {
  .grid-6-col {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid-4-col {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .page_head .pageHead {
    /* float: none; */
  }
  .page_head .page_actions {
    text-align: left;
    /* float: none; */
    margin-bottom: 20px;
  }
  .page_head .page_actions button {
    float: none;
    margin: 0 10px 0 0 !important;
  }
}

@media (max-width: 767px) {
  .header {
    padding: 0 12px;
  }

  .header a,
  .logo {
    font-size: 13px !important;
  }

  .head-rgt > div {
    font-size: 13px !important;
  }

  .head-date,
  .headPipe {
    display: none;
  }
}

@media (max-width: 600px) {
  .grid-3-col,
  .grid-1-col {
    grid-template-columns: 1fr;
  }
  .page_actions {
    text-align: left;
    float: none;
  }
}
@media (max-width: 480px) {
  .grid-3-col,
  .grid-1-col {
    grid-template-columns: 1fr;
  }
}

/* input:focus {
    background-color: #fafafa;
  }
  .Mui-focused {
    background-color: #fafafa;
  } */

.red_highlight {
  color: red;
  font-weight: 600;
}
.fr {
  float: right;
}

.tbl_jsm .MuiTableCell-root {
  padding: 3px;
}

.tbl_header {
  padding: 10px;
  text-align: right;
}

.bl_lrCheckboxes {
  max-height: 200px;
  overflow: auto;
  margin-bottom: 10px;
}

.error {
  color: red !important;
}

.btn-style {
  height: 32px;
  font-size: 13px !important;
  border-radius: 3px !important;
  width: 95px;
}

.nav-arrow {
  position: absolute !important;
  top: 14px;
  right: 12px;
  cursor: pointer;
}

.userpButtons {
  position: absolute;
  right: 34px;
}

.frm-bottom-spc .css-12ihmyg-MuiPaper-root {
  padding-bottom: 60px !important;
}

.left-panel::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.left-panel::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.left-panel::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.left-panel:horizontal {
  height: 5px;
}

body {
  scrollbar-width: thin;
  /* scrollbar-color: #90A4AE #CFD8DC; */
}

.left-panel::-webkit-scrollbar-track {
  background: transparent !important;
  border-radius: 6px;
  height: 5px;
}

.left-panel::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  border: 1px solid #eaeaea;
  min-height: 40px;
  max-height: 80px;
}

.left-panel::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
